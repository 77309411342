@import '@fontsource/inter/400.css'; /* Regular */
@import '@fontsource/inter/500.css'; /* Medium */
@import '@fontsource/inter/600.css'; /* Semi-Bold */
@import '@fontsource/inter/700.css'; /* Bold */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .fetch-input,
  .fetch-textarea,
  .fetch-select,
  .fetch-multiselect {
    @apply mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3
           focus:outline-none focus:ring-brandPrimary focus:border-brandPrimary sm:text-sm !important;
  }

  .fetch-label {
    @apply block text-sm font-bold text-gray-700 text-left
  }

  .fetch-button {
    @apply w-full bg-brandPrimary text-white py-2 px-4 rounded-md
           hover:bg-brandAccent transition duration-300;
  }

  .fetch-progress-bar {
    @apply w-full bg-gray-200 rounded-full h-2;
  }

  .fetch-progress-bar-fill {
    @apply bg-brandPrimary h-2 rounded-full;
  }

  .fetch-card {
    @apply max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .fetch-header {
    @apply bg-white shadow-md sticky top-0 z-10 p-4 flex items-center;
  }

  .fetch-header-wrapper {
    @apply sticky top-0 z-10 bg-white shadow-md;
    width: 100%;
    max-width: 42rem; /* This matches the max-width of fetch-card */
    margin: 0 auto;
  }

  .fetch-header-content {
    @apply p-4 flex flex-col items-start;
  }

  .fetch-header-top {
    @apply flex items-center justify-between w-full mb-2 relative;
  }

  .fetch-progress {
    @apply w-full;
  }

  .fetch-back-button {
    @apply h-6 w-6 text-brandPrimary cursor-pointer;
    position: absolute;
    left: 0;
  }

  .fetch-back-button-placeholder {
    @apply h-6 w-6;
  }

  .fetch-page-title {
    @apply text-xl md:text-3xl font-bold text-center flex-grow;
  }

  .fetch-container {
    @apply flex-grow container mx-auto px-0;
  }

  .fetch-form {
    @apply space-y-6 mt-0;
  }

  .fetch-required {
    @apply text-red-500;
  }

  .fetch-link {
    @apply text-brandPrimary hover:underline;
  }

  .fetch-checkbox {
    @apply h-4 w-4 text-brandPrimary focus:ring-indigo-500 border-gray-300 rounded;
  }

  .fetch-checkbox-label {
    @apply ml-2 block text-sm font-bold text-gray-900;
  }

  .fetch-secondary-text {
    @apply text-sm text-gray-600 mb-1;
  }

  .file-input-button {
    @apply cursor-pointer bg-brandPrimary text-white py-2 px-4 rounded-md
           hover:bg-brandHover transition duration-300 flex items-center justify-center;
  }

  .tall-editor .ql-editor {
    min-height: 200x; /* Adjust this value as needed */
  }

  .fetch-job-card {
    @apply bg-white rounded-lg shadow-md p-6 mb-4 cursor-pointer transition duration-300 hover:shadow-lg w-full max-w-2xl mx-auto;
    border-left: 4px solid #7F6FEA; /* Custom brandPrimary border */  }

  .fetch-job-title {
    @apply text-xl font-bold mb-0 text-left;
  }

  .fetch-job-company {
    @apply text-sm font-semibold mb-1;
  }

  .fetch-job-details {
    @apply text-sm text-gray-500 text-left;
  }

  .fetch-filter-container {
    @apply bg-white rounded-lg shadow-md p-6 mb-4;
    border-left: 4px solid #7F6FEA; /* Custom brandPrimary border */  }

  .fetch-filter-title {
    @apply text-lg font-semibold mb-4 text-left;
  }
  
  .fetch-filter-group {
    @apply mb-4 text-left;
  }
  
  .fetch-filter-label {
    @apply block text-sm font-bold text-gray-700 mb-2 text-left;
  }
  
  .fetch-filter-checkbox {
    @apply mr-2;
  }

  .fetch-filter-button {
    @apply w-full bg-brandPrimary text-white py-2 px-4 rounded-md hover:bg-brandHover transition duration-300;
  }

  .fetch-input[type="datetime-local"] {
    @apply appearance-none;
  }
  
  .fetch-input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    @apply float-right;
  }

  .ql-editor h1,
  .job-description h1 {
    font-size: 2em;
    font-weight: bold;
  }
  
  .ql-editor h2,
  .job-description h2 {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .ql-editor a,
  .job-description a {
    color: blue;
    text-decoration: underline;
  }

  /* Style for ordered (numbered) lists */
  .ql-editor ol {
    list-style-type: decimal !important;
    margin-left: 20px;
  }

  /* Style for unordered (bulleted) lists */
  .ql-editor ul {
    list-style-type: disc !important;
    margin-left: 20px;
  }

  /* List item style */
  .ql-editor li {
  margin-bottom: 5px;
  }

  .job-description {
    @apply text-left;
  }
  
  .job-description h1 {
    @apply text-2xl font-bold mb-4;
  }
  
  .job-description h2 {
    @apply text-xl font-bold mb-3;
  }
  
  .job-description h3 {
    @apply text-lg font-bold mb-2;
  }
  
  .job-description p {
    @apply mb-4;
  }
  
  .job-description ul, 
  .job-description ol {
    @apply list-disc list-inside mb-4 pl-5;
  }
  
  .job-description ol {
    @apply list-decimal;
  }
  
  .job-description li {
    @apply mb-2;
  }
  
  .job-description a {
    @apply text-blue-600 underline;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

  @media (max-width: 640px) {
    .fetch-input[type="datetime-local"] {
      font-size: 0.875rem; /* 14px */
      padding: 0.5rem 0.75rem;
    }
  }

  .quill-container {
    display: flex;
    flex-direction: column;
  }
  
  .quill-container .quill {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .quill-container .ql-container {
    flex: 1;
    overflow: auto;
  }
  
  .quill-container .ql-editor {
    min-height: 100px;
  }
  
  @media (max-width: 640px) {
    .quill-container {
      height: 250px !important;
    }
  }

  .bg-indigo-50 {
    background-color: #7F6FEA;
  }

  /* Notification styles */
.notification {
  @apply flex items-center p-4 mb-4 text-sm rounded-lg shadow-md;
  @apply fixed bottom-5 right-5 max-w-sm;
  @apply transform transition-all ease-in-out duration-300;
}

.notification-success {
  @apply bg-green-100 text-green-700;
}

.notification-error {
  @apply bg-red-100 text-red-700;
}

.notification-warning {
  @apply bg-yellow-100 text-yellow-700;
}

.notification-info {
  @apply bg-blue-100 text-blue-700;
}

.notification-enter {
  @apply opacity-0 translate-y-5;
}

.notification-exit {
  @apply opacity-0 translate-y-5;
}

.fetch-tag {
  @apply text-xs font-semibold px-2.5 py-0.5 rounded mb-1;
}

.fetch-tag-recommended {
  @apply bg-indigo-100 text-indigo-800;
}

.fetch-tag-former-epic {
  @apply bg-red-100 text-red-800;
}

.fetch-button-secondary {
  @apply inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-brandHover bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.fetch-title {
  @apply text-3xl font-august text-java2;
}

.fetch-subtitle {
  @apply text-lg font-montserrat;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fetchCarousel .slick-dots li button:before {
  color: #4F46E5;
}

.fetchCarousel .slick-dots li.slick-active button:before {
  color: #4F46E5;
}

.fetchCarousel .slick-prev:before,
.fetchCarousel .slick-next:before {
  color: #4F46E5;
}

.date-picker-wrapper {
  @apply w-full;
}

.date-picker-custom {
  @apply w-full text-left;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  @apply w-full;
}

.react-datepicker__input-container input {
  @apply w-full py-2 px-3 text-sm;
}

.date-picker-popper {
  @apply z-50;
}

.react-datepicker {
  @apply text-sm;
}

.react-datepicker__header {
  @apply bg-gray-100;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply w-8 h-8 leading-8;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-brandPrimary text-white;
}

.react-datepicker__time-container {
  @apply w-24;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  @apply w-24;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  @apply bg-brandPrimary text-white;
}

.react-datepicker__time-list-item {
  @apply h-auto !important;
}

.fetch-tag-new {
  background-color: #e0f2ff;
  color: #0070f3;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  margin-top: 4px;
}

/* In tailwind.css or directly in your component */
th {
  @apply px-6 py-3 text-left text-sm font-medium text-gray-700 hover:bg-brandAccent transition-colors;
}

tr:hover {
  @apply bg-indigo-50;
}

.table-wrapper {
  position: relative;
  overflow: visible; /* Ensure dropdowns are not clipped */
}

.relative-dropdown {
  position: absolute;
  z-index: 10;
}

.fetch-multiselect .rmsc {
  --rmsc-main: #4F46E5;
  --rmsc-hover: #5742e3;
  --rmsc-selected: #7F6FEA;
  --rmsc-border: #e2e8f0;
  --rmsc-gray: #64748b;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
}

.fetch-multiselect .rmsc .dropdown-heading {
  padding: 10px;
}

.fetch-multiselect .rmsc .dropdown-content {
  border-radius: 0 0 4px 4px;
}

.half-star {
  position: relative;
  overflow: hidden;
}

.half-star:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: white;
}

.tooltip {
  @apply absolute right-0 mb-2 w-64 p-2 bg-gray-700 text-white text-sm rounded shadow-lg z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300;
}
}